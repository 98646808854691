import React from 'react'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { getStaticPageSeoDetails } from '@utils-blog'
import { Hero } from '@layout'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { getSymposiumSeriesData } from '../../helpers/nr-symposium-series.helper'
import { getSymposiumSeriesHeroData } from '../../helpers/nr-symposium-series-hero'
import HeroComponent from '../../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  boxs: {
    display: `flex`,
    flexWrap: `wrap`
  },
  box: {
    flex: `0 0 33.33%`,
    p: 20,
    '> div': {
      height: '100%'
    }
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  bgBoxWrap: {
    background: 'linear-gradient( 0deg, #000000 0%, transparent 100% )',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  bgBox: {
    position: 'absolute',
    top: 50,
    left: '50%',
    transform: 'translate(-50%,0)',
    right: 0,
    bottom: 0,
    pt: 10,
    pb: '4rem',
    pr: '2rem',
    pl: '2rem',
    width: '100%',
    maxWidth: '1140px',
    display: 'flex',
    alignItems: 'end'
  },
  textColor: {
    color: '#fff',
    fontFamily: `'Spectral',serif`
  },
  staticBox: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
    transition:
      'transform 250ms ease 0s, box-shadow 250ms ease 0s, color 250ms ease 0s',
    boxShadow: 'rgb(1 1 1 / 5%) 1px 1px 5px 0px',
    p: 25,
    mb: 15
  }
}

export default props => {
  const data = getSymposiumSeriesData()
  const heroData = getSymposiumSeriesHeroData()

  let seoData = getStaticPageSeoDetails('shared.symposium-series')
  const symposiumSeoData = {
    ...seoData,
    Symposium: {
      ...seoData?.Symposium,
      keywords: seoData?.Symposium?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={symposiumSeoData?.Symposium?.metaTitle}
        description={symposiumSeoData?.Symposium?.metaDescription}
        keywords={symposiumSeoData?.Symposium?.keywords}
        thumbnail={symposiumSeoData?.Symposium?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'symposiumSeries'}
            {...props}
            callFrom='Symposium Series'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Hero>       
        <CardList
          isPartner={true}
          isSymposium={true}
          nodes={data}
          callFrom='symposium'
          columns={[1]}
          variant={['horizontal-md', 'horizontal']}
          aside
        />
      </Hero>
    </Layout>
  )
}
